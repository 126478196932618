

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$primary: #fcda00;
$font-color-100: #fefac0;
$contrast: #222222;
$ram: #DFA042;


:root {
  --background-color: #f7f7f7;
  --background-color-2: #ffffff;
  --background-color-answer: rgba(107, 106, 98, 0.8);
  --background-color-question: rgba(211, 211, 206, 0.8);
  --box-border: rgba(0, 0, 0, 0.4);
  --text-color: #222222;
  --mat-table-row-item-label-text-font: 'Figtree', sans-serif;
  --mat-table-header-headline-font: 'Figtree', sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: black;
    --background-color-2: #222222;
    --background-color-question: rgba(94, 93, 86, 0.8);
    --box-border: rgba(255, 255, 255, 0.4);
    --text-color: rgba(255, 255, 255, 0.87);
  }
}


/* You can add global styles to this file, and also import other style files */

::ng-deep .mat-mdc-text-field-wrapper {
  background-color: var(--background-color-2);
}

$accent: #fda800;

$shau-palette: (
  50:  #fffde5,
  100: #fefac0,
  200: #fdf695,
  300: #fcf268,
  400: #f9ed40,
  500: #f6e800,
  600: $primary,
  700: #fcc200,
  800: $accent,
  900: #fc7c00,
  ram: $ram,
  primary: $primary,
  darker-primary: #7e6c00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    // ... continues to 900
  )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$shau-primary: mat.define-palette($shau-palette);
$shau-accent: mat.define-palette($shau-palette, 800, 800, 800);
$shau-accent-dark: mat.define-palette($shau-palette, 600, 600, 600);

// The warn palette is optional (defaults to red).
$shau-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$shau-theme-dark: mat.define-dark-theme((
  color: (
    primary: $shau-primary,
    accent: $shau-accent-dark,
    warn: $shau-warn,
  ),
));

$shau-theme-light: mat.define-light-theme((
  color: (
    primary: $shau-primary,
    accent: $shau-accent,
    warn: $shau-warn,
  ),
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($shau-theme-light);


// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.all-component-themes($shau-theme-light);

  a,
  a:visited,
  a:hover {
    color: $accent;
  }
}

// Apply the dark theme when the user prefers dark themes.
@media (prefers-color-scheme: dark) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  // This mixin only generates the color styles now.
  @include mat.all-component-themes($shau-theme-dark);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary;
  }
}

/* Media query for smartphones */
@media only screen and (max-width: 768px) {
  /* Styles for smartphones go here */
  body {
    word-break: break-word;
  }

  a {
    word-break: break-all;
  }
}

.horizontal-center {
  margin-left: auto;
  margin-right: auto;
}

body {
  height: 100vh;
  margin: 0;
}